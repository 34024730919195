<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader :storage="appLoading" />
    <v-layout wrap pa-3>
      <v-flex xs12>
        <span class="itemHeading1">Profile Details</span>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card flat>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12 sm2 text-center align-self-center>
              <v-avatar color="#FFF9C4" size="70">
                <v-img
                contain
                width="100%"
                height="150px"
                src="@/assets/images/avatar.png"
                fill-height
              ></v-img>
                <!-- <v-img
                  v-if="userdetails.username"
                  :src="mediaURL + appUser.photo"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FDCE48"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-img v-else :src="mediaURL + 'noimage.jpg'">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FDCE48"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img> -->
              </v-avatar>
            </v-flex>
            <v-flex xs12 sm10>
              <v-layout wrap justify-start>
                <v-flex xs6 sm3 pt-2 v-if="userdetails.username">
                  <v-flex xs12 text-left>
                    <span class="itemKey">Name</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ userdetails.username }}
                      <span v-if="!userdetails.username">-</span>
                    </span>
                  </v-flex>
                </v-flex>

                <v-flex xs6 sm3 pt-2 v-if="userdetails.phone">
                  <v-flex xs12 text-left>
                    <span class="itemKey">Phone</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ userdetails.phone }}
                      <span v-if="!userdetails.phone">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6 sm3 pt-2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Email</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ userdetails.email }}
                      <span v-if="!userdetails.email">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6 sm3 pt-2 v-if="userdetails.division">
                  <v-flex xs12 text-left>
                    <span class="itemKey">Division</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ userdetails.divisionName }}
                      <span v-if="!userdetails.divisionName">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6 sm3 pt-2 v-if="userdetails.range">
                  <v-flex xs12 text-left>
                    <span class="itemKey">Range</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ userdetails.rangeName }}
                      <span v-if="!userdetails.rangeName">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6 sm3 pt-2 v-if="userdetails.station">
                  <v-flex xs12 text-left>
                    <span class="itemKey">Station</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ userdetails.station }}
                      <span v-if="!userdetails.station">-</span>
                    </span>
                  </v-flex>
                </v-flex>

                <v-flex xs6 sm3 v-if="userdetails.type">
                  <v-flex xs12 text-left>
                    <span class="itemKey">Role</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ userdetails.type }}
                      <span v-if="!userdetails.type">-</span>
                    </span>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex md12>
        <v-layout wrap>
          <v-flex
            v-for="item in titleArray"
            text-center
            @click="header = item.value"
            style="cursor: pointer"
            :key="item.value"
          >
            <span
              class="text-uppercase"
              style="font-family: sofiaProBold; letter-spacing: 0.6px"
            >
              {{ item.title }}</span
            >
            <v-progress-linear
              v-if="header == item.value"
              :value="100"
              :color="appColor"
              height="2"
            ></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex md12 :key="header" v-if="header == 'Reported'">
            <ReportDetails :status="header" />
          </v-flex>
          <v-flex md12 :key="header" v-if="header == 'Assigned'">
            <ReportDetails :status="header" />
          </v-flex>
          <v-flex md12 :key="header" v-if="header == 'Captured'">
            <ReportDetails :status="header" />
          </v-flex>
          <v-flex md12 :key="header" v-if="header == 'Resolved'">
            <ResolveDetails :status="header" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ReportDetails from "./myReports";
import ResolveDetails from "./myResolve.vue"
export default {
  components: {
    ReportDetails,
    ResolveDetails
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      userName: localStorage.getItem("userName"),
      msg: null,
      tabValue: 0,
      header: "Reported",
      titleArray: [
        { title: "MY CASES", value: "Reported" },
        { title: "ASSIGNED CASES", value: "Assigned" },
        { title: "RELEASED CASES", value: "Captured" },
        { title: "RESOLVED CASES", value: "Resolved" },

        // { title: "COTTAGES", value: "cottage" },
      ],
      userdetails: [],
    };
  },

  beforeMount() {
    this.getData();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/details",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          username: this.userName,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response) {
            this.userdetails = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>